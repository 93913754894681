import { render, staticRenderFns } from "./index.vue?vue&type=template&id=58b357ea"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=58b357ea&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzContainer: require('/opt/razlet.ru.v2/node_modules/@razlet/ui/src/components/container/index.vue').default,IndexInfoButtons: require('/opt/razlet.ru.v2/src/components/index/info-buttons.vue').default,IndexNewsList: require('/opt/razlet.ru.v2/src/components/index/news-list.vue').default,SuluContent: require('/opt/razlet.ru.v2/node_modules/@razlet/sulu-sdk/src/components/content.vue').default})
